export const darkColors = {
  // background/text color for the whole app
  primaryBg: "#1a1a1a",
  primaryText: "#fff",
  
  // secondary background/text color for the whole app
  secondaryBg: "#686868",
  secondaryText: "#a4a4a4",

  extremelyLightBg: "#1c1c1c",
  lightBg: "#313131", // modify this to grey20

  //accent color for the app
  accent: "#e6ae48",

  //translucent background/text color for the whole app
  translucentBg: "rgba(0, 0, 0, 0.5)",
  // background/text color for the for primary buttons
  primaryButtonBg: "#e6ae48",
  primaryButtonText: "#fff",
  primaryButtonBgHover: "#ecaf3e",

  // background/text color for the for success buttons
  successButtonBg: "#28a745",
  successButtonText: "#fff",
  successButtonBgHover: "#28a745",

  // background/text color for the for danger buttons
  dangerButtonBg: "#dc3545",
  dangerButtonText: "#fff",
  dangerButtonBgHover: "#dc3545",

  // background/text color for the for warning buttons
  warningButtonBg: "#ffc107",
  warningButtonText: "#fff",
  warningButtonBgHover: "#ffc107",

  // background color for loader
  loaderColor: "#fff",

  //header-background
  headerBg: "#22222261",
  footerBg: "#22222261",
  bannerBgColor: 'transparent',
  bannerTextColor: '#fff',
  logoColor: "#e6ae48",
};

export const lightColors = {
  // background/text color for the whole app
  primaryBg: "#fff",  // Light gray background
  primaryText: "#000",   // Dark gray text

  // secondary background/text color for the whole app
  secondaryBg: "rgb(33, 150, 243)",    // White background
  secondaryText: "#fff",  // Slightly darker gray text

  //a bit calmer background
  extremelyLightBg: "#fff", // modify this to grey10
  lightBg: "#f8f8f8", // modify this to grey20

  // Accent color for the app
  accent: "#000",

  // Translucent background/text color for the whole app
  translucentBg: "rgba(255, 255, 255, 0.9)",  // Semi-transparent white

  // Background/text color for the primary buttons
  primaryButtonBg: "#2196f3",  // Blue background
  primaryButtonText: "#fff",    // White text
  primaryButtonBgHover: "#308cca",  // Slightly darker blue on hover

  // Background/text color for the success buttons
  successButtonBg: "#28a745",  // Green background
  successButtonText: "#fff",    // White text
  successButtonBgHover: "#218838",  // Slightly darker green on hover

  // Background/text color for the danger buttons
  dangerButtonBg: "#dc3545",  // Red background
  dangerButtonText: "#fff",    // White text
  dangerButtonBgHover: "#c82333",  // Slightly darker red on hover

  // Background/text color for the warning buttons
  warningButtonBg: "#e0a800",  // Yellow background
  warningButtonText: "#fff",    // White text
  warningButtonBgHover: "#ffc107",  // Slightly darker yellow on hover

  borderButtonColor: "#ccc",  // Light gray border

  // Background color for loader
  loaderColor: "#333",  // Dark gray loader

  // Header background
  headerBgColor: "#fff",  // Light gray header
  headerTextColor: "#000",  // Dark gray text
  footerBgColor: "rgb(33, 150, 243)",
  footerTextColor: "#fff",
  bannerBgColor: 'transparent',
  bannerTextColor: '#fff',
  logoColor: "#e6ae48",
};

